import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaCalendarCheck, FaQuestion, FaMoon, FaRunning, FaToggleOff, FaToggleOn, FaSync } from "react-icons/fa";
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './_components';
import { HomePage } from './HomePage';
import { LoginPage } from './LoginPage';
import logo from './logo.svg';
import './App.css';
import FarmPanel from './Farm';
import HomePanel from './Home';
import LoginPanel from './Login';
import WipPanel from './Wip';

function App() {

  const [data, setData] = useState(null);
  const [srvrhide, setHide] = useState(false);
  const [schedhide, setSched] = useState(false);
  const [allowed, setAllowed] = useState(false);

  const srvrHandler = () => {
    setHide(!srvrhide)
  }
  const schedHandler = () => {
    setSched(!schedhide)
  }

  useEffect(() => {
    fetch(`https://ctrl2.cherry2024.com:2096/getservers`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="App">
      <main className="App-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              {data ?
                <div className="container-fluid">
                  <div className="row  ">
                    <div className="col-sm-12  border-top border-bottom border-start  border-end border-info">
                      <Navbar fixed="top" bg="dark" data-bs-theme="dark" expand="lg" className="bg-body-tertiary">
                        <Container>
                          <Navbar.Brand href="#home" className="rem-big">&nbsp;Cherry2024 Remote Control&nbsp;</Navbar.Brand>
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                              <div className="shorttext">
                                &nbsp;&nbsp;
                                <span className="togglecheck ">
                                  <input
                                    type="checkbox"
                                    id="detailctrl"
                                    checked={srvrhide}
                                    onChange={srvrHandler}
                                  />
                                </span>&nbsp;&nbsp;
                                <label htmlFor="detailctrl">
                                  {srvrhide
                                    ? <span><span className="toggle up cursortoggle"><FaToggleOn /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Hide Bots</span> </span>
                                    : <span><span className="toggle detailtxt cursortoggle"><FaToggleOff /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Show Bots</span> </span>
                                  }
                                </label>
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                              </div>
                              <div className="shorttext">
                                <span className="togglecheck ">
                                  <input
                                    type="checkbox"
                                    id="schedctrl"
                                    checked={schedhide}
                                    onChange={schedHandler}
                                  />
                                </span>&nbsp;&nbsp;
                                <label htmlFor="schedctrl">
                                  {schedhide
                                    ? <span><span className="toggle up cursortoggle"><FaToggleOn /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle">Hide Schedule </span> </span>
                                    : <span><span className="toggle detailtxt cursortoggle"><FaToggleOff /></span>&nbsp;&nbsp;<span className="detailtxt biginfo cursortoggle"> Show Schedule </span> </span>
                                  }
                                </label>
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                              </div>
                              <div className="shorttext d-flex align-items-center justify-content-center">
                                <a target="_blank" href="http://dash.cherry2024.com:7004/public-dashboards/5cbdf8fa852f44f7b32a4c08a006bd86" className="btn btn-primary btn-sm active" role="button">Live Farms</a>
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                              </div>
                              <div className="shorttext">
                                &nbsp;&nbsp;
                                <HomePanel />
                              </div>
                            </Nav>
                          </Navbar.Collapse>
                        </Container>
                      </Navbar>
                    </div>
                  </div>
                  <div className="row  ">
                    <div className="col-sm-12">
                      <br></br>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                  <div className="row">
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={1}
                        farmname={"Onsite"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot1"}
                      />
                    </span>
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={2}
                        farmname={"Limitless"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot3"}
                      />
                    </span>
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={3}
                        farmname={"Logistics"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot1"}
                      />
                    </span>
                  </div>
                  <div className="row">
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={4}
                        farmname={"Dolphin"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot4"}
                      />
                    </span>
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={5}
                        farmname={"Spanish"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot3"}
                      />
                    </span>
                    <span className="col-sm-12 col-md-4">
                      <FarmPanel
                        farmServers={data}
                        farmno={6}
                        farmname={"Marina"}
                        detail={srvrhide}
                        sched={schedhide}
                        group={"-Plot4"}
                      />
                    </span>
                  </div>
                  <div className="row">
                    <span className="col-sm-6">
                      &nbsp;
                      {(srvrhide) && <div>
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          <h3>Working with Farms/Bots</h3>
                          <p>
                            If a Login/Account for a Farm is at OCC maximum, stop the Farm for that Account so that other Farms can get the Clients. A Farm at OCC
                            maximum that is still running will cause other Farms to miss the Client.</p>
                          <p>If you stop SOME of the Bots in a Farm, the Farm becomes weaker and less likely to get a client. You can do this if you want other
                            Farms covering the same area to have a better chance for Clients.</p>
                        </div>
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          <FaRunning />=The Bot is running normally. There are no overrides in effect. If you see an Unresponsive message, the server may be down.
                        </div>
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          <span className="up" >on</span>/<span className="down" >off</span>=The Bot is under the control of this panel. This control only lasts for today.
                          Tomorrow, the normal schedule will be applied.
                        </div>
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          To refresh all the bots, Hide and then Show the Bots. This will refresh the current remote control status for
                          each Bot. You can also refesh each Bot individually (&nbsp;<FaSync />&nbsp;).
                        </div>
                        <div className="padit align-items-center justify-content-center border border-info detailhdr">
                          To see the Server that is running the Bot, move (position) the mouse over the Start or Stop buttons in the Farm Robots.
                        </div>
                      </div>
                      }
                    </span>
                    <span className="col-sm-6">
                      &nbsp;
                      {(schedhide) &&
                        <div>
                          <div className="padit align-items-center justify-content-center border border-info detailhdr">
                            <h3>Working with Schedules</h3>
                            <p>
                              Just click on a Date in the Calendar to make changes.</p>
                          </div>
                          <div className="padit align-items-center justify-content-center border border-info detailhdr">
                            Date Symbols: &nbsp;
                            <span className="calon justify-content-center runningman shorttext col-sm-3">
                              < FaRunning />&nbsp;=&nbsp;ON
                            </span> &nbsp;
                            <span className=" caloff justify-content-center runningman shorttext col-sm-3">
                              < FaMoon />&nbsp;=&nbsp;OFF
                            </span> &nbsp;
                            <span className="calnot justify-content-center runningman shorttext col-sm-6">
                              < FaQuestion />&nbsp;=&nbsp;NO&nbsp;SCHEDULE
                            </span>
                          </div>
                          <div className="padit align-items-center justify-content-center border border-info detailhdr">
                            <FaCalendarCheck />&nbsp;This is a rolling 3 month calendar always starting on today.<br></br>
                            <FaCalendarCheck />&nbsp;Clicking on a date toggles it on and off.<br></br>
                            <FaCalendarCheck />&nbsp;Click on a < FaQuestion /> = NO SCHEDULE&nbsp;
                            day to set the robot to run on that date. This is how you add new dates to the schedule for a farm.<br></br>
                            <FaCalendarCheck />&nbsp;If today has NO SCHEDULE, the bot will run on a fixed Mon-Sat schedule.<br></br>
                            <FaCalendarCheck />&nbsp;Robots start at 5am and stop at 9pm.<br></br>
                          </div>
                        </div>
                      }

                    </span>
                  </div>
                </div>
                : 'Loading...'}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;

